<template>
  <div id="app">
    <button
      id="mute"
      class="mute-button"
      v-on:click="$store.dispatch('toggleAudio')"
      v-if="this.$store.state.currentRoute !== 'Preload'"
    >
      <img
        class="mute-image"
        :src="
          $store.state.audio
            ? require('@/assets/audio_on.png')
            : require('@/assets/audio_off.png')
        "
      />
    </button>
    <component :is="$store.state.viewComponent"></component>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.$store.commit("navigate", { route: "Preload" });
  },
};
</script>

<style>
:root {
  --yellow: #ffd24f;
  --star-yellow: #ffe12d;
  --blue: #22acef;
  --light-blue: #ade8ff;
  --dark-blue: #004374;
  --padding-top: 35px;
  --padding-sides: 30px;
  --top-bar-blue: #23abee;
  --blue-gray: #2c3e50;
  --off-white: #fef7e8;
  --light-red: #ff7966;
  --dark-red: #ed422d;
  --text-blue: #343a51;
  --teal: #53d2db;
  --score-color: #0d4762;
  --light-green: #69db7c;
}

@font-face {
  font-family: "Quicksand";
  src: url("assets/fonts/Quicksand/Quicksand-VariableFont_wght.ttf");
  font-weight: normal;
}

body {
  margin: 0;
}

#app {
  font-family: "Quicksand", Arial, sans-serif !important;
  font-variation-settings: "wght" 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--light-blue);
  color: var(--blue-gray);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

button {
  cursor: pointer;
}

.mute-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.mute-image {
  height: 4vmin;
  max-height: 50px;
  min-height: 30px;
}

.yellow-buttons {
  background-color: var(--yellow);
  font-size: clamp(12px, 2vw, 3vh);
  font-family: inherit;
  font-weight: bold;
  border-radius: 50px;
  padding: 5px 25px;
  border-style: solid;
  border-color: white;
  border-width: 4px;
  box-shadow: 0px 5px 0px var(--blue);
  color: var(--text-blue);
}

.red-buttons {
  background-color: var(--light-red);
  font-size: clamp(12px, 2vw, 3vh);
  font-family: inherit;
  font-weight: bold;
  border-radius: 50px;
  border: none;
  padding: 5px 25px;
  box-shadow: 0px 5px 0px var(--dark-red);
  color: var(--text-blue);
  margin: 1vh;
}

.mobile > * .red-buttons,
.red-buttons.mobile,
.mobile > * .yellow-buttons,
.yellow-buttons.mobile {
  font-size: clamp(12px, 3vw, 3vh);
}

.audio {
  margin: 0 0.5em;
  height: 3em;
  width: 3em;
  max-height: 50px;
  max-width: 50px;
  border-style: solid;
  border-color: white;
  border-radius: 50%;
  border-width: 0.25em;
  z-index: 1;
}

.reward-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-container {
  position: relative;
  box-sizing: border-box;
  width: calc(50vh / 0.7);
  height: calc(50vh);
  background-image: url("./assets/rewards/background.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reward-images {
  display: flex;
  height: calc(100% - 1.9vh);
  width: calc(100% - 2.3vh);
  align-items: flex-start;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  border-radius: 10.2vh;
}

.reward-images.left {
  justify-content: flex-start;
  background-position-x: right;
}

.reward-images.right {
  justify-content: flex-end;
  background-position-x: left;
}

.reward-image {
  width: calc(30vh / 0.75);
  height: calc(30vh);
  margin: 7vh 2vh;
}

.mobile > * .reward-container {
  width: 80vw;
  height: calc(80vw * 0.7);
}

.title-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(12vh / 0.362);
  height: calc(12vh);
  padding: 0 calc(12vh * 0.1) calc(12vh * 0.1) calc(12vh * 0.1);
  background-image: url("./assets/rewards/titleContainer.png");
  background-size: contain;
  background-repeat: no-repeat;
  box-sizing: border-box;
  font-variation-settings: "wght" 700;
  top: 0;
  transform: translate(0, -50%);
  font-size: min(3vh, 30px);
}

.mobile > * .reward-image {
  width: 40vw;
  height: 30vw;
}

.reward-button {
  position: absolute;
  bottom: 0;
  transform: translate(0, 250%);
}

.left-pop {
  -webkit-animation: scale-up-left 0.5s;
  animation: scale-up-left 0.5s;
}

.right-pop {
  -webkit-animation: scale-up-right 0.5s;
  animation: scale-up-right 0.5s;
}

.center-pop {
  -webkit-animation: expand-bounce 0.8s;
  animation: expand-bounce 0.8s;
}

/* ANIMATIONS */
@-webkit-keyframes expand-bounce {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes expand-bounce {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes scale-up-left {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}
@keyframes scale-up-left {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
  }
}

@-webkit-keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
@keyframes scale-up-right {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
  }
}
</style>
