import keyboardJS from "keyboardjs";
import store from "./store";
import $ from "jquery";

export class Focusable {
  constructor({ element }) {
    this.focus = false;
    this.element = element;
  }
}

export class FocusManager {
  constructor() {
    keyboardJS.bind(["enter", "space"], this.activate.bind(this));
  }
  clearFocus() {
    for (const focusable of store.state.focusables) {
      focusable.focus = false;
    }
  }
  activate(e) {
    if (e.target.id === "next-setup") {
      $("#next-setup").trigger("click");
    }
  }
}
