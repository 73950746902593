import Vue from "vue";
import Vuex from "vuex";
import content from "../../public/content.json";
import $ from "jquery";
import _ from "underscore";
import keyboardJS from "keyboardjs";
import { Focusable } from "../focusManager";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    audio: true,
    backgroundMusic: "",
    componentData: {
      title: null,
      buttonText: null,
      nextRoute: null,
    },
    content: {},
    correct: false,
    currentRoute: "",
    currentIndex: "",
    currentItem: "",
    currentQuestionType: "",
    decisionCounter: 1,
    devMode: false,
    feedback: "",
    focusables: [],
    focusManager: "",
    loadedSounds: [],
    mobileView: false,
    mode: "",
    nextButtonText: "Next Question",
    percentageComplete: 0,
    resultCounter: 1,
    results: {},
    rewards: {
      basketball: "Slam Dunk",
      baseball: "Home Run",
      soccer: "GOOOOAL",
      football: "Touchdown",
    },
    round1: [],
    round2: [],
    round3: [],
    round4: [],
    roundCounter: 1,
    roundScore: 0,
    score: 0,
    setupCounter: 1,
    showFeedback: false,
    showResults: false,
    showRewardModal: false,
    showRoundFeedback: false,
    showQuestion: false,
    timeout: null,
    urlEntries: {},
    viewComponent: "",
    windowHeight: 0,
    windowWidth: 0,
  },
  mutations: {
    initGame(state) {
      state.round1 = content["I."];
      state.round2 = content["II."];
      state.round3 = content["III."];
      state.round4 = content["IIII."];
      state.content = {};
      //  reset variables for replay
      state.roundCounter = 1;
      state.decisionCounter = 1;
      state.setupCounter = 1;
      state.resultCounter = 1;
      state.correct = false;
      state.nextButtonText = "Next Question";
      state.percentageComplete = 0;
      state.score = 0;
      state.roundScore = 0;
      state.showFeedback = false;
      state.showResults = false;
      state.showRewardModal = false;
      state.showRoundFeedback = false;
      state.showQuestion = false;

      window.addEventListener("resize", () => {
        state.windowHeight = window.innerHeight;
        state.windowWidth = window.innerWidth;
        let breakpoint = 850;
        if ($("body").width() > breakpoint) {
          state.mobileView = false;
        } else {
          state.mobileView = true;
        }
      });
      for (const [key, object] of Object.entries(content)) {
        let round = "round" + (key.length - 1);
        state.content[round] = {};
        for (let row of object) {
          let num = parseInt(row.id.split("-")[2]);
          let type = row.id.split("-")[1];
          if (!isNaN(num)) {
            typeof state.content[round]["decision" + num] === "undefined"
              ? (state.content[round]["decision" + num] = {})
              : "";
            typeof state.content[round]["decision" + num][type] === "undefined"
              ? (state.content[round]["decision" + num][type] = {})
              : "";
            state.content[round]["decision" + num][type][row.id] = row;
          } else {
            typeof state.content[round]["info"] === "undefined"
              ? (state.content[round]["info"] = {})
              : "";
            let name = row.id;
            if (name.split("-")[0] === "character") {
              typeof state.content[round].info["characters"] === "undefined"
                ? (state.content[round].info["characters"] = {})
                : "";
              state.content[round].info.characters[name] = row;
              state.content[round].info.characters[name].text = state.content[
                round
              ].info.characters[name].text.replace(/\s/g, "-");
            } else {
              state.content[round].info[name] = row;
            }
          }
        }
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const entries = urlParams.entries();
      for (const entry of entries) {
        state.devMode = true;
        switch (entry[0]) {
          case "round":
            state.roundCounter = parseInt(entry[1]);
            state.decisionCounter = 1;
            state.setupCounter = 1;
            break;
          case "decision":
            state.decisionCounter = parseInt(entry[1]);
            state.setupCounter = 1;
            break;
          case "setup":
            state.setupCounter = parseInt(entry[1]);
            break;
          default:
            state.urlEntries[entry[0]] = entry[1];
            break;
        }
      }
      if (
        state.content["round" + state.roundCounter].info.title.interaction ===
        "in person"
      ) {
        state.mode = "inperson";
      } else if (
        state.content["round" + state.roundCounter].info.title.interaction ===
        "text message"
      ) {
        state.mode = "textmessage";
      } else {
        state.mode = "postit";
      }
      state.percentageComplete =
        ((state.decisionCounter - 1) /
          (Object.keys(state.content["round" + state.roundCounter]).length -
            1)) *
        100;
    },
    toggleAudioState(state) {
      state.audio = !state.audio;
    },
    changeQuestionState(state, visibility) {
      state.showQuestion = visibility;
    },
    navigate(state, { route, data }) {
      if (route === "RoadMap") {
        let thisSound = state.backgroundMusic;
        if (!thisSound.playing()) {
          thisSound.play();
        }
      }
      keyboardJS.setContext(route);
      state.currentRoute = route;
      import(`@/components/${route}`).then((module) => {
        state.viewComponent = module.default;
        state.componentData = data;
      });
    },
    setProgress(state, percent) {
      state.percentageComplete = percent;
    },
    scrollToEnd(state, containerId) {
      let container = document.getElementById(containerId);
      container.scrollTop = container.scrollHeight;
    },
    updateScore(state, amount) {
      state.roundScore += amount;
      state.score += amount;
    },
    getFocusableItems(state, scope = document) {
      state.focusables = [];
      const keyboardfocusableElements = scope.querySelectorAll(
        'a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      );
      for (const element of keyboardfocusableElements) {
        state.focusables.push(
          new Focusable({
            element: element,
          })
        );
      }
    },
    playAudio(state, name) {
      // name is the id column of the spreadsheet
      let thisSound = state.loadedSounds[name];
      thisSound.play();
    },
    stopAllSounds(state) {
      for (const sound of Object.values(state.loadedSounds)) {
        sound.off(); // Remove any event listeners.
        sound.stop();
      }
    },
    updateCurrent(state) {
      state.currentIndex =
        state.roundCounter +
        "-" +
        (state.showQuestion ? "question" : "setup") +
        "-" +
        state.decisionCounter +
        (state.showQuestion ? "" : "-" + state.setupCounter);
      state.currentItem = _.findWhere(state["round" + state.roundCounter], {
        id: state.currentIndex,
      });
    },
    updateCurrentItemById(state, id) {
      state.currentIndex = id;
      state.currentItem = _.findWhere(state["round" + state.roundCounter], {
        id: state.currentIndex,
      });
    },
    getCurrentQuestionType(state) {
      state.currentQuestionType =
        state.content["round" + state.roundCounter][
          "decision" + state.decisionCounter
        ].question[
          state.roundCounter + "-question-" + state.decisionCounter
        ].questiontype;
    },
    updateFeedbackState(state) {
      state.showFeedback = true;
    },
  },
  actions: {
    playVO({ commit, state }, name) {
      if (state.timeout){
            clearTimeout(state.timeout);
      }
      commit("stopAllSounds");
      commit("playAudio", name);
    },
    toggleAudio({ commit, state }) {
      commit("toggleAudioState");
      if (!state.audio) {
        commit("stopAllSounds");
        state.backgroundMusic.volume(0);
      } else {
        state.backgroundMusic.volume(0.2);
      }
    },
  },
  modules: {},
});
